.forgot-password-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 90px;
}

.forgot-password-main-container {
  border: 1px solid #ffe1e1;
  width: 75%;
  max-width: 600px;
  padding: 20px;
}

.forgot-password-loader-container {
  width: 100%;
}

.forgot-password-main-container button {
  padding: 10px 20px;
  border: 1px solid transparent;
  width: 100%;
  background-color: #860505;
  color: white;
  outline: none;
  font-family: "Merriweather";
}

.forgot-password-main-container button:hover {
  background-color: #470303;
}

.error-messages-container {
  width: 100%;
  height: 24px;
  color: red;
  text-align: center;
}
