.my-account-loader-container {
  width: 100%;
}

.my-account-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 90px;
}

.my-account-main-container {
  border: 1px solid #ffe1e1;
  width: 75%;
  max-width: 600px;
  padding: 20px;
}

.logo-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

input[type="date"] {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.my-account-main-container button {
  padding: 10px 20px;
  border: 1px solid transparent;
  width: 100%;
  background-color: #860505;
  color: white;
  outline: none;
  font-family: "Merriweather";
}

.my-account-main-container button:hover {
  background-color: #470303;
}
