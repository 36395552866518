.English,
.Tamil {
  margin: 5%;
  text-align: center;
}

.English {
  font-family: "Roboto Slab";
}

.Tamil {
  font-family: "Mukta Malar";
}

.container {
  display: flex;
  flex-direction: column;
}

.content {
  margin: 3%;
  order: 1;
}

.image {
  margin: 3%;
  order: 2;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }

  .content {
    margin: 3%;
    padding-top: 1%;
    order: 1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    margin: 3%;
    order: 0;
    width: 50%;
  }

  .reverse-order {
    flex-direction: row-reverse;
  }
}

.slide-in-from-right-container {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-in-from-right-container.visible {
  opacity: 1;
  transform: translateX(0);
}

.slide-in-from-left-container {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-in-from-left-container.visible {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 767px) {
  .slide-in-from-right-container {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .slide-in-from-right-container.visible {
    opacity: 1;
    transform: translateY(0);
  }

  .slide-in-from-left-container {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .slide-in-from-left-container.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.cu-container {
  width: 100%;
  padding: 5%;
  background-color: #470303;
  color: white;
  text-align: center;
  display: flex;
}

.box {
  width: 33%;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  /* Adjust styles for mobile view */
  .cu-container {
    flex-direction: column; /* Stack boxes on top of each other */
  }

  .box {
    width: 100%; /* Take full width in mobile view */
  }
}
