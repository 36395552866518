.photo-slider {
  width: 75%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .photo-slider {
    width: 90%;
    height: 65vh; /* Set height to auto or any other value for mobile views */
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
}

.slick-slide {
  display: none;
  float: left;
  height: fit-content;
  min-height: 1px;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  list-style: none;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  display: inline-block;
  margin: 0 4px;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  border: none;
  border-radius: 50%;
  background: #888;
  cursor: pointer;
  transition: background 0.3s;
}

.slick-dots li.slick-active button {
  background: #333;
}

.slider-images {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .slider-images {
    width: 100%;
    height: 65vh; /* Set height to auto or any other value for mobile views */
    object-fit: cover;
  }
}
