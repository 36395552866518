.cdrates-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 90px;
}

.cdrates-manage-container {
  border: 1px solid #ffe1e1;
  min-width: 50%;
  max-width: 90%;
}

.cdrates-toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.cdrates-toggle-buttons button {
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  background-color: #fff7f7;
  border-bottom: 3px solid transparent;
  font-family: "Merriweather";
  font-size: small;
}

.cdrates-toggle-buttons button.active {
  background-color: #fddfdf;
  border-bottom: 3px solid #470303;
}

.logo-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.logo-img-container img {
  width: 75px;
  height: 75px;
}

.add-cdrates-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.add-cdrates-form h6 {
  text-align: center;
  font-family: "Merriweather";
}

label {
  display: block !important;
  font-family: "Merriweather";
  font-size: small;
}

input[type="text"],
input[type="number"] {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  margin-bottom: 10px;
}

#add-cdrates-button {
  padding: 10px 20px;
  border: 1px solid transparent;
  width: 100%;
  background-color: #860505;
  color: white;
  outline: none;
  font-family: "Merriweather";
  border-radius: 5px;
}

#add-cdrates-button:hover {
  background-color: #470303;
}

.response-messages-container {
  width: 100%;
  height: 24px;
  text-align: center;
}

.view-cdrates-section {
  padding: 10px;
}

.view-cdrates-section th {
  text-align: center;
}

.view-cdrates-section td {
  text-align: end;
}

.view-cdrates-section h6 {
  text-align: center;
  font-family: "Merriweather";
}

.view-cdrates-section button {
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #860505;
  color: white;
  outline: none;
  font-family: "Merriweather";
  font-size: small;
}

.view-cdrates-section button:hover {
  background-color: #470303;
}
