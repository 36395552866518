.change-password-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 90px;
}

.change-password-main-container {
  border: 1px solid #ffe1e1;
  width: 75%;
  max-width: 600px;
  padding: 20px;
}

.change-password-loader-container {
  width: 100%;
}

.change-password-main-container button {
  padding: 10px 20px;
  border: 1px solid transparent;
  width: 100%;
  background-color: #860505;
  color: white;
  outline: none;
  font-family: "Merriweather";
}

.change-password-main-container button:hover {
  background-color: #470303;
}

.primary-button {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #860505;
  color: white;
  outline: none;
  font-family: "Merriweather";
}

.primary-button:hover {
  background-color: #470303;
}

.secondary-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #edbaba;
  color: black;
  outline: none;
  font-family: "Merriweather";
}

.secondary-button:hover {
  background-color: #e17d7d;
}
