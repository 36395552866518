.navbar {
  background-color: #470303;
  margin: 0;
}

#nav-container {
  display: contents;
}

#navbar-brand-logo {
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  width: 30px;
}

.navbar-brand {
  margin-left: 20px;
  font-family: "Pacifico";
  font-size: 18px !important;
}

.custom-navbar-link {
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Merriweather";
  font-size: 14px;
  color: white;
  text-decoration: none;
}

.custom-navbar-link:hover {
  color: #ffc107;
}

#langugage-toggle-button {
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Roboto Slab";
  font-weight: 700;
}

#custom-button {
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Roboto Slab";
  font-size: small;
  font-weight: 800;
  margin-bottom: 5px;
  background-color: #ffc107;
  color: #430000;
  border-color: #ffc107;
}

#custom-button:hover {
  background-color: #eaa224;
  border-color: #eaa224;
}

.my-profile-dropdown {
  color: white;
  margin-left: 10px;
  font-family: "Roboto Slab";
  padding-right: 10px;
}

.my-profile-dropdown .dropdown-item {
  width: 90%;
}

@media (max-width: 767px) {
  .my-profile-dropdown .dropdown-menu {
    width: 94vw; /* Set the width to full viewport width */
    min-width: unset; /* Remove minimum width for mobile */
    max-width: unset; /* Remove maximum width for mobile */
  }
}
